














stripPerc = (val) -> parseFloat val.replace('%', '')
import card from './card'
export default

	components: { card }

	props:
		item: Object
		columnWidth: String
		gutterWidth: String
		noParallax: Boolean

	data: ->

		# Add a slight amount of random parallax
		parallax: if @noParallax then undefined
		else Math.random() * (range = .2) - range/2

	computed:

		# Acccessor for the item content
		content: -> @item?.content

		# Create the card data
		card: -> switch @content?.sys?.contentType?.sys.id
			when 'card' then @item.content
			when 'caseStudy'
				title: @content.client
				abstract: @content.abstract
				image: @content.gridImage
				video: @content.gridVideo
				cta: 'View Work'
				url: "/work/#{@content.slug}"
				color: @content.color

		# Convert masonry widths to numbers
		widthInt: -> stripPerc @item.width
		columnWidthInt: -> stripPerc @columnWidth
		gutterWidthInt: -> stripPerc @gutterWidth

		# How many columns does this span
		cols: -> @widthInt / 10

		# The width needs to be less the gutter size.
		width: ->
			width = if @cols == 10 then 100
			else @cols * @columnWidthInt + (@cols - 1) * @gutterWidthInt
			return "#{width}%"

