






















import masonryGrid from '~/components/global/masonry-grid'
import gridItem from './item'
import { loadRefs } from '~/utils'
export default

	components: {
		masonryGrid
		gridItem
	}

	props:
		block: Object

	# Load grid items and case studies
	asyncData: loadRefs('gridItem', 'items')

	data: ->

		# Calclaute the gutter width based on the width of a column
		columnWidth: "#{(colW = 6)}%"
		gutterWidth: "#{(100 - colW * 10)/9}%"

	computed:

		# Remove items that lack case studies which can happen during dev
		items: -> @block.items.filter (item) -> item.width and item.content?.id

		# Classes that get added to the block
		classes: -> [
			@$cssClass @block.maxWidth, 'max-width'
			'stagger' unless @block.disableStagger
		]


