












export default

	props: block: Object

	computed:

		# Set CSS vars used by fluid function in stylus
		styles: ->
			'--desktop-height': (desktop = @defaultIfNull @block.desktopHeight, 150)
			'--mobile-height': @defaultIfNull @block.mobileHeight,  desktop * 0.7

	methods:

		# Return default value if null
		defaultIfNull: (val, def) -> if val == null then def else val


