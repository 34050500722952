


















export default

	props: block: Object

	components: asyncForm: -> import('./form')

