



























export aspect = 362/446
export default

	props:
		person: Object
		aspect:
			type: Number
			default: aspect
		autoColor:
			type: Boolean
			default: true

