

















import onResize from '~/utils/on-resize-mixin'
export default

	mixins: [ onResize ]

	data: ->
		trackW: null
		sliderW: null
		offset: 0

	# Instantiate Impetus
	mounted: ->
		@impetus = new @$Impetus
			source: @$el
			update: @onImpetusUpdate
			preventScroll: true
		@setBounds()

	# Cleanup
	destroyed: -> @impetus.destroy()

	computed:

		# Momentum needs a defined width
		styles: -> transform: "translate3d(#{@offset}px,0,0)"

		# Disable if the trackW is not greater thatn the viewport width
		enabled: ->
			return true unless @trackW and @viewportW
			@trackW > @viewportW

	watch:

		# Disable Impetus when there isn't the width to justify it
		enabled: ->
			if @enabled
				@impetus?.resume()
			else
				@impetus?.pause()
				@offset = 0

	methods:

		# Store the offset of slider
		onImpetusUpdate: (@offset) ->

		# Set the bounds to be the width of the content
		onResize: ->
			@trackW = @$refs.track.scrollWidth
			@sliderW = @$el.offsetWidth
			@setBounds()

		# Set the Impetous bounds
		setBounds: ->
			return unless @trackW && @sliderW && @impetus
			@impetus.setBoundX [bounds = @sliderW - @trackW, 0]

			# Make sure the track stays within those bounds
			@offset = Math.min 0, Math.max bounds, @offset
			@impetus.setValues @offset, 0

