


























import horizontalNav from './horizontal-nav'
export default

	components: { horizontalNav }

	data: ->
		social:
			instagram: 'https://www.instagram.com/bkwld/'
			vimeo: 'https://vimeo.com/bkwld'
			linkedin: 'https://www.linkedin.com/company/bkwld'
			twitter: 'https://www.twitter.com/bkwld'

