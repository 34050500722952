





















import quote from '../quotes/full'
export default

	components: { quote }

	props: slide: Object

	computed:

		# Set classes
		classes: -> 'has-quote': !!@slide.quote

		# Set CSS vars used by fluid function in stylus
		styles: ->
			'--desktop-width': (desktop = @slide.desktopWidth || 460)
			'--mobile-width': @slide.mobileWidth || desktop * 0.7

