###
DRY up shared tower code
###

# Import Blocks
import blockCarousel from '~/components/blocks/carousel'
import blockCopy from '~/components/blocks/copy'
import blockForm from '~/components/blocks/form'
import blockFullBleed from '~/components/blocks/full-bleed'
import blockLogos from '~/components/blocks/logos'
import blockGrid from '~/components/blocks/grid'
import blockMarquee from '~/components/blocks/marquee'
import blockPeople from '~/components/blocks/people'
import blockQuotes from '~/components/blocks/quotes'
import blockServices from '~/components/blocks/services'
import blockSlider from '~/components/blocks/slider'
import blockSpacer from '~/components/blocks/spacer'
import blockTicker from '~/components/blocks/ticker'

# List of block components
blocks = {
	blockCarousel
	blockCopy
	blockForm
	blockFullBleed
	blockLogos
	blockGrid
	blockMarquee
	blockPeople
	blockQuotes
	blockServices
	blockSlider
	blockSpacer
	blockTicker
}

# Export just the list of keys
export keys = Object.keys blocks

# The mixin that tower-like pages use
export mixin =

	# Block components
	components: blocks

# Helper for loading async data from blocks
export loadBlockData = (context, towerBlocks = [], extra) ->
	Promise.all towerBlocks.map (block) ->
		type = block?.sys.contentType?.sys.id
		if func = blocks[type]?.asyncData
		then func(context, block, extra)
		else Promise.resolve()
