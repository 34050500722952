















import onResize from '~/utils/on-resize-mixin'
import { duration as leaveDuration } from '~/utils/page-transition-mixin'
export default

	mixins: [ onResize ]

	props:

		# Masonry settings
		columnWidth:
			type: String
			default: '25%'
		gutterWidth:
			type: String
			default: '0'
		itemSelector:
			type: String
			default: '.masonry-grid > :not(.column-sizer):not(.gutter-sizer)'
		gutter:
			type: Number
			default: 0

		# Have masonry.layout() fire on resize events. Disabled by default because
		# it's expensive and requires a little more from config of grid elements
		# to prevent layout thrashing.
		updateOnResize:
			type: Boolean
			default: true

		# Fire masonry.layout() when viewport width crosses this threshold.
		# Disabled by default because using updateOnResize.
		breakpoints:
			type: Array
			default: -> []

	# Init masonry
	mounted: ->
		@masonry = new @$masonry @$el,

			# Sizing of columnss
			itemSelector: @default
			columnWidth: @$refs.columnSize
			gutter: @$refs.gutterSize
			percentPosition: true

			# Don't animate reflows
			transitionDuration: 0

			# Don't respond to resizing, we generally have 2 columns so it's either
			# active or it's stacked
			resize: false

			# Delay initing so we can register layoutComplete event
			initLayout: false

		# Use root as an event bus for masonry layout complete events.  Delaying a
		# tick so the DOM changes have committed before reactive Vue bindings do
		# things.
		@masonry.on 'layoutComplete', =>
			@$wait 0, => @$root.$emit 'masonry:layoutComplete'

		# Actually init the layout
		@masonry.layout()

	# Cleanup once the loading has finished so the page doesn't reflow at the
	# _start_ of the leaving transition
	destroyed: -> @$wait leaveDuration * 1000, => @masonry?.destroy()

	watch:

		# If the change in width crosses over a breakpoint, recalc the layout
		viewportW: (now, old) ->
			for breakpoint in @breakpoints
				@masonry?.layout() if (now >= breakpoint and old < breakpoint) or
				(now <= breakpoint and old > breakpoint)

	methods:

		# Resize Masonry after scroll-responders have a chance to do their thing.
		onResize: ->
			if @updateOnResize
				@$wait 0, => @masonry?.layout()

