









































import { loadRefs } from '~/utils'
export default

	props:
		block: Object

	# Load slides
	asyncData: loadRefs 'caseStudy', 'slides', 1, [
			'fields.carouselImage'
			'fields.carouselVideo'
			'fields.abstract'
			'fields.slug'
			'fields.color'
			'fields.client'
		]

	data: -> active: 0

	computed:

		# Filter the slides to ones with values.  They may be empty when on a
		# case study and the slide is for the current case study.  It appears
		# Contentful is doing that automatically
		slides: -> (@block.slides || []).filter (val) -> !!val

		# Find the active case study
		activeSlide: -> @slides[@active]

		# Make the active route
		activeRoute: -> "/work/#{@activeSlide.slug}"

		# Return the background color class of the active case study
		colorClass: -> @$cssClass @activeSlide.color, 'background'

