














export default

	props: block: Object

	computed:

		# Make the route to the contact form
		route: ->
			if slug = @$route.params.case_study
			then "/contact/case-study-request?caseStudy=#{slug}"
			else '/contact/form'

		# Support a default title
		headline: -> @block.contactHeadline || 'Interested in the full case study?'

