

















export default

	props:
		quote: Object
		size:
			type: String
			default: 'large'

