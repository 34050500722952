

























import cta from './cta'
export default

	components: { cta }

	props: block: Object

	computed:

		# Break the services list into 2 arrays such that the order they are in is
		# preserved when read right to left
		services: ->
			@block.services?.split "\n"
			.reduce (list, service, index) ->
				col = index % 2
				list[col].push service
				return list
			, [[],[]]

