




















import onResize from '~/utils/on-resize-mixin'
import onScroll from '~/utils/on-scroll-mixin'
import inViewport from 'vue-in-viewport-mixin'
import item from './item'
export default

	mixins: [ onResize, onScroll, inViewport ]

	components: { item }

	props:
		block: Object

		# Trigger playback early so that there isn't a delay on iOS
		inViewportRootMargin: default: '200px 0px 200px 0px'

	data: ->
		playing: false # Is currently playing
		x: 0 # The x offset of the items
		lastTimestamp: null # Time of last onFrame() call
		itemsWidth: 0 # The sum of all items widths
		removedWidth: 0 # The sum of items that were removed after being displayed
		items: [] # The list of items used by visible items
		adding: false # True if a item has been added to items array but not mounted
		scrollDelta: 0 # The scroll delta reduced to 0 over time
		scrollDelta: null

	# Populate the inital amount needed
	mounted: ->
		@addItemToEnd()
		@conditionallyStartOrStop()

	# Cleanup when done
	destroyed: -> @stop()

	computed:

		# Classes to add to the styling
		classes: -> [
			'custom-font-size' if @block.fontSize
		]

		# Styles applied to the container
		styles: ->
			transform: "translateX(#{@x}px)"
			paddingLeft: "#{@removedWidth}px"
			'--font-size': @block.fontSize

		# Get the width of the displayed items
		displayedWidth: -> @itemsWidth + @x + @removedWidth

		# Adjust the speed by the amount of scroll
		speed: -> (@block.speed || 15) + @speedScrollAdjustment

		# The amount to adjust the speed by, respecting direction
		speedScrollAdjustment: -> @scrollDelta * @block.speed

		# VueX shorthand
		transitioning: -> @$store.state.route.transitioning

	watch:

		# Store the scroll delta, which is applied to the speed
		scrollY: (now, old) -> @scrollDelta = now - old

		# Start/stop when the transition is done
		transitioning: -> @conditionallyStartOrStop()

		# Start/stop in responce to viewport changes
		'inViewport.now': -> @conditionallyStartOrStop()

		# Sync the playing variable
		playing: -> if @playing then @start() else @stop()

	methods:

		# Start or stop based on page transition and in viewport state
		conditionallyStartOrStop: ->
			@playing = not @transitioning and @inViewport.now

		# Start and stop ticking
		start: ->
			@stop() # Just in case, prevent multiple RAFs
			@rafId = requestAnimationFrame @onFrame
		stop: -> cancelAnimationFrame @rafId if @rafId

		# Measure width of window
		onResize: ->
			@cacheItemWidths()
			@sumItemWidths()

		# Animate the the slider, using the timestamp so it shouldn't be affected
		# by the framrate
		onFrame: (timestamp) ->
			@lerpScrollDelta()
			if @lastTimestamp and timestamp > @lastTimestamp
				@moveTicker timestamp
				@addItemIfNecessary()
				@removeOffScreenItems()
			@lastTimestamp = timestamp
			@rafId = window.requestAnimationFrame @onFrame

		# Apply LERPing to the scrollDelta on touch devices.  This LERP's the change
		# in delta between scrollDelta (the first multiplier) and then decays it
		# (the second multiplier)
		lerpScrollDelta: ->
			return unless Modernizr.touchevents
			@scrollDelta = ((@scrollDelta - @lastSD) * .2 + @lastSD) * 0.9 if @lastSD
			@lastSD = @scrollDelta

		# Update the X position
		moveTicker: (timestamp) -> @x -= @speed / (timestamp - @lastTimestamp)

		# Re-cache all the item's widths
		cacheItemWidths: ->
			(@$refs.items || []).forEach (ref) -> ref.cacheWidth()

		# Total the width of all slides
		sumItemWidths: ->
			@itemsWidth = (@$refs.items || []).reduce (sum, ref) =>
				sum + ref.width || 0
			, 0

		# Add another item to the list
		addItemIfNecessary: ->
			return if @adding
			if @displayedWidth < @viewportW then @addItemToEnd()
			else if @x + @removedWidth > 0 then @addItemToStart()

		# Add another item to the list
		addItemToEnd: ->
			@adding = 'push'
			@items.push
				copy: @block.copy
				key: Math.random()

		# Add an item to the start of the list
		addItemToStart: ->
			@adding = 'unshift'
			@items.unshift
				copy: @block.copy
				key: Math.random()

		# When a uten is added, check if we need to add more.
		onItemAdded: (itemVm) ->

			# Increae the count of the width
			@itemsWidth += itemVm.width

			# If adding to the beginning, reduce the removedWidth.  However, if this
			# is negative, like it might be if initializing as part of scrolling up
			# into viewport, then advance the scroll by the the item width and
			# compensate the removed width accordingly.
			@removedWidth -= itemVm.width if @adding == 'unshift'
			if @removedWidth < 0
				@removedWidth += itemVm.width
				@x -= itemVm.width

			# Mark adding as done and add more if needed
			@adding = false
			@addItemIfNecessary()

		# Remove items when off screen
		removeOffScreenItems: ->
			return unless firstItemWidth = @$refs.items?[0]?.width
			return unless firstItemWidth + @removedWidth + @x < 0
			@items.splice 0, 1 # Remove first item

			# Adjust calculated values
			@itemsWidth -= firstItemWidth
			@removedWidth += firstItemWidth # Used as padding on container

