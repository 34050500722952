








































export default

	props:
		card: Object

	computed:

		# Simplify the card style
		cardStyle: -> switch @card.style
			when 'Button CTA' then 'button'
			else 'image'

