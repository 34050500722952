

















import fullQuote from './full'
import columnQuote from './column'
import { loadRefs } from '~/utils'
export default

	components: { fullQuote, columnQuote }

	props: block: Object

	# Get quotes and and authors
	asyncData: loadRefs 'quote', 'quotes'

	computed:

		# Determine which quote style to render basec on the layout choice
		componentName: -> switch @block.layout
			when 'Full' then 'fullQuote'
			when 'Columns' then 'columnQuote'

