

















import horizontalSlider from '~/components/global/horizontal-slider'
import slide from './slide'
import { loadRefs } from '~/utils'
export default

	components: {
		horizontalSlider
		slide
	}

	# Fetch the slides data.  Need additional references to get the quote author.
	asyncData: loadRefs 'slide', 'slides', 2

	props:
		block: Object

	computed:

		# Add class to add max-width or not, basically
		indentClass: -> if @block.indent then 'indent-max-w' else 'indent-viewport'

