




































import { TweenLite, Expo } from '~/plugins/gsap'
import onResize from '~/utils/on-resize-mixin'
export default

	mixins: [ onResize  ]

	props: block: Object

	data: ->
		marqueeBottom: null
		imageryBottom: null

	# Tween the intro text in on mount
	mounted: -> TweenLite.from @$refs.intro, 1.5,
		ease: Expo.easeOut
		y: '100vh'
		delay: .5 # Wait for page transition

	computed:

		# Is there a visual
		hasVisual: -> @block.image || @block.video

		# Use the aspect ratio of the image, if there is one, or default to 16:9
		aspect: ->
			if aspect = @$contentful.aspect @block.image
			then aspect else 16/9

		# How much parallax to apply to the headline, eyeballed
		headlineParallax: ->
			unless @hasVisual then 0
			else -2 * ((min = .5) + (@viewportPercent * (1 - min)))

		# The scroll distance until the marquee (with internal padding) starts
		# to leave the viewport
		marqueeTravel: -> @marqueeBottom - @viewportH

		# The distance the imagery must travel via the scrollResponder's
		# translate until it appears to rest at the bottom of the marquee
		imageryTravel: -> @marqueeBottom - @imageryBottom

	methods:

		# Apply parallax effects to the imagery
		imageryTween: (scrollY) ->

			# The progress of scrolling the marquee out of the viewport
			percent = Math.min 1, scrollY / @marqueeTravel

			# The parallax effect really just moves the imagery from it's starting
			# place to the bottom of the marquee over the distance that the user
			# must scroll to take the marquee out of the viewport.  The main factors
			# effecting this distance is the aspect ratio of the visual and the
			# padding-bottom of the bottom of the marquee-block. The Math.pow serves
			# to slightly delay the effect of the initial scrolls while not being
			# so extreme that the direction appears to reverse.
			return @imageryTravel * Math.pow percent, 0.9

		# Measure dimensions of marquee elements
		onResize: ->
			return unless @hasVisual
			@marqueeBottom = @$el.getBoundingClientRect().bottom + @$scroll.y
			@imageryBottom = @$refs.imagery.getBoundingClientRect().bottom + @$scroll.y

