






























export default

	props:
		block: Object

	computed:

		# Classes to add to the styling
		classes: -> [
			@$cssClass @block.maxWidth, 'max-width'
			@$cssClass @block.listLayout, 'list-layout'
			@$cssClass @block.textStyle, 'text-style'
			'custom-font-size' if @block.fontSize
			'has-image' if !!@block.image
		]

		# Styles to add
		styles: ->
			'--font-size': @block.fontSize

		# Conditionlly add vue-unorphan
		allowUnorphan: -> !@block.listLayout or @block.listLayout == 'Bulleted'

