












export default

	props: item: Object

	data: -> width: null

	# Trigger when mounted
	mounted: ->
		@cacheWidth()
		@$emit 'mounted', @

	methods:

		# Store the current width
		cacheWidth: -> @width = @$el.offsetWidth

