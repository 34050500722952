















import person from '~/components/blocks/people/person'
export default

	components: { person }

	props: quote: Object

