
















# Deps
import { keys, mixin } from '~/utils/has-blocks'
import lazyHydrate from 'vue-lazy-hydration'

# Duck punch own hydrate method that fires an event on hydration
originalHydrateMethod = lazyHydrate.methods.hydrate
lazyHydrate.methods.hydrate = ->
	originalHydrateMethod.call @
	@$emit 'hydrated'

# Export component
export default

	components: { lazyHydrate }

	mixins: [ mixin ]

	props: blocks: Array

	computed:

		# Filter the blocks to those that have been defined.  To fix errors in dev
		# environments after content model is created but commits with the new
		# block component have not been pulled down.
		createdBlocks: -> (@blocks || []).filter (block) ->
			keys.includes block?.sys?.contentType?.sys?.id

	methods:

		# Recalculate scroll info when components are hydrated
		onHydrate: -> @$scrollbar.recalculate()

