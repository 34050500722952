























import person, { aspect } from './person'
import shuffle from 'lodash/shuffle'
import { loadRefs } from '~/utils'
export default

	components: { person }

	props: block: Object

	# Load people
	asyncData: loadRefs 'person', 'people', 1, [
			'fields.slug'
			'fields.image'
			'fields.name'
			'fields.title'
		]

	data: -> aspect: aspect

	computed:

		# Optionally randomize the order of people
		people: ->
			if @block.randomize
			then shuffle @block.people
			else @block.people

