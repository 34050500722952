###
The shared transition between pages. This approach is designed to trigger the
transition immediately upon nuxt load and to also allow the incoming page to
start loading as soon as possible.  It would not work if the site were to use
nuxt-child based child routes since it assumes that any nuxt loading event is
for a full page tranision
###
import { TweenLite, Expo } from '~/plugins/gsap'

# Transition settings
export duration = 1.2
ease = Expo.easeInOut

# Vars needed to coordinate the leaving tween
leavePromise = null
scrollTopPromise = null

# Export the tween
export default

	# Listen for the load to start which signals this to leave
	mounted: -> @$root.$once 'loading:started', @onPageLeave

	transition:

		# Use gasp for tweening
		css: false

		# Instantiate new page as soon as possible
		mode: ''

		# Wait for the onPageLeave animation to finish
		leave: (el, done) ->
			await leavePromise
			leavePromise = null
			done()

		# Before enter runs ...
		beforeEnter: (el) ->

			# Hide current page until scroll to top finishes
			el.classList.add 'before-enter'

			# Add custom cursors to all links
			@$addCustomLinkCursors el

		# Animate in the new page
		enter: (el, done) ->

			# When scroll is finished, this page can be revealed
			await scrollTopPromise
			el.classList.remove 'before-enter' if el

			# Recalculate scrollbar position
			@$scrollbar.recalculate()

			# Triggers current page to update in nav
			@$store.commit 'route/set', @$route

			# Handle case that maybe the page was already deleted
			return done() unless el

			# Tween the new page in
			TweenLite.fromTo el, duration, x: '100vw' ,
				ease: ease
				x: 0
				rotationY: 0
				onComplete: done

		# After loading and transition are done
		afterEnter: ->

			# Remove transitioning class when done
			document.body.classList.remove 'page-transitioning'

			# Remove loading cursor
			@$root.$emit 'cursor:hide', 'loading'

			# Update vuex state
			@$store.commit 'route/transitioning', false

	methods:

		# Play outro animation and set a promise that can be used to track
		# completion
		onPageLeave: ->

			# Make a promise that resovles when leave is totally finished
			leavePromise = new Promise (done) =>

				# Update vuex state
				@$store.commit 'route/transitioning', true

				# Show loading cursor
				@$root.$emit 'cursor:show', 'loading'

				# Add page leave class to body
				document.body.classList.add 'page-transitioning'

				# Scroll to top of page
				scrollTopPromise = @$scrollbar.scrollTo 0
				await scrollTopPromise

				# Handle case that maybe the page was already deleted
				return done() unless @$el

				# Position absolute it so it doesn't mess with positiong of the
				# incoming page
				@$el.style.position = 'absolute'
				@$el.style.width = '100vw'

				# Do the tween resolve the promise when finished
				TweenLite.to @$el, duration,
					ease: ease
					x: '-100vw'
					onComplete: done
